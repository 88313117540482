import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Alert, Spinner, Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { formatNumber } from "redux/action/api";
import "./customtable.css";

export default function ProposalTable({ data, fields, type, tableRef, stage }) {
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = stage === "breakdown" ? data?.length : 20;

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = data?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderCell = (item, field, index) => {
    if (field.custom) {
      return field.component(item, index);
    }
    return item[field.key];
  };

  return (
    <div className="custom-table-container">
      <table className={`table table-striped table-bordered`} ref={tableRef}>
        <thead>
          <tr>
            {fields.map((field, index) => (
              <th key={index} style={{ width: field.width, fontSize: "13px" }}>
                {field.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentData?.map((item, index) => (
            <React.Fragment key={index}>
              <tr
                style={{
                  borderLeftWidth: "10px",
                  borderColor:
                    type === "mopb"
                      ? item?.status === "final-proposal"
                        ? "orange"
                        : "green"
                      : type === "proposals"
                      ? item?.status === "draft"
                        ? "orange"
                        : "green"
                      : type === "exco"
                      ? item.status === "final-mopb"
                        ? "orange"
                        : "green"
                      : type === "house"
                      ? item.status === "final-exco"
                        ? "orange"
                        : "green"
                      : type === "approve"
                      ? item.status === "final-house"
                        ? "orange"
                        : "green"
                      : "",
                  fontSize: "13px",
                }}
              >
                {fields?.map((field, fieldIndex) => (
                  <td
                    key={fieldIndex}
                    rowSpan={field.rowSpan && item.type_of_budget === "Capital" ? 3 : 1}
                    style={field.style}
                  >
                    {renderCell(item, field, index)}
                  </td>
                ))}
              </tr>
              {item?.type_of_budget === "Capital" && (
                <>
                  <tr style={{
                    borderLeftWidth: "10px",
                    borderColor:
                      type === "mopb"
                        ? item?.status === "final-proposal"
                          ? "orange"
                          : "green"
                        : type === "proposals"
                        ? item?.status === "draft"
                          ? "orange"
                          : "green"
                        : type === "exco"
                        ? item.status === "final-mopb"
                          ? "orange"
                          : "green"
                        : type === "house"
                        ? item.status === "final-exco"
                          ? "orange"
                          : "green"
                        : type === "approve"
                        ? item.status === "final-house"
                          ? "orange"
                          : "green"
                        : "",
                    fontSize: "13px",
                  }}>
                    <td>{item.fund_desc2}</td>
                    <td>
                      {item.fund_code2.startsWith('0') ? (
                        <p>{`'${item.fund_code2}`}</p>
                      ) : (
                        <p>{`${item.fund_code2}`}</p>
                      )}
                    </td>
                    <td style={{ textAlign: "right" }} className="text-right">
                      {formatNumber(item.fund_amt2)}
                    </td>
                  </tr>
                  <tr style={{
                    borderLeftWidth: "10px",
                    borderColor:
                      type === "mopb"
                        ? item?.status === "final-proposal"
                          ? "orange"
                          : "green"
                        : type === "proposals"
                        ? item?.status === "draft"
                          ? "orange"
                          : "green"
                        : type === "exco"
                        ? item.status === "final-mopb"
                          ? "orange"
                          : "green"
                        : type === "house"
                        ? item.status === "final-exco"
                          ? "orange"
                          : "green"
                        : type === "approve"
                        ? item.status === "final-house"
                          ? "orange"
                          : "green"
                        : "",
                    fontSize: "13px",
                  }}>
                    <td>{item.fund_desc3}</td>
                    <td>
                      {item.fund_code3.startsWith('0') ? (
                        <p>{`'${item.fund_code3}`}</p>
                      ) : (
                        <p>{`${item.fund_code3}`}</p>
                      )}
                    </td>
                    <td style={{ textAlign: "right" }} className="text-right">
                      {formatNumber(item.fund_amt3)}
                    </td>
                  </tr>
                </>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {isLoading ? (
        <div className="loading-container text-center">
          <Spinner color="primary" style={{ marginTop: "0px" }} /> Loading...
        </div>
      ) : data?.length === 0 ? (
        <div className="container">
          <center>
            <Alert color="primary">No Data Available Right Now !!</Alert>
          </center>
        </div>
      ) : (
        <>
          <Pagination className="pagination justify-content-center">
            {Array.from({ length: Math.ceil(data?.length / itemsPerPage) })?.map((_, index) => (
              <PaginationItem key={index} active={index + 1 === currentPage}>
                <PaginationLink onClick={() => paginate(index + 1)}>
                  {index + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
          </Pagination>
        </>
      )}
    </div>
  );
}

// Prop validation using PropTypes
ProposalTable.propTypes = {
  data: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  tableRef: PropTypes.object,
  stage: PropTypes.string.isRequired,
};

